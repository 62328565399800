window.__tnt || (window.__tnt = {});
__tnt.user || (__tnt.user = {});

__tnt.subscription || (__tnt.subscription = {});
__tnt.subscription.access || (__tnt.subscription.access = {});
__tnt.subscription.access.refererUrl || (window.__tnt.subscription.access.refererUrl = false);
__tnt.subscription.access.displayReceipt || (window.__tnt.subscription.access.displayReceipt = false);
__tnt.subscription.offers || (__tnt.subscription.offers = {});

window.tntPurchase = document.createEvent('Event');
    tntPurchase.initEvent('tnt-purchase',true,true,{});

window.tntOfferPopulateModal = document.createEvent('Event');
    tntOfferPopulateModal.initEvent('tnt-offer-populate-modal',true,true,{});

__tnt.user.updateLoggedIn = function() {
    if(TNCMS.User.isLoggedIn()){
        __tnt.user.update();
        
        var assetContent = document.getElementById('asset-content'), 
            modalTabLoginSuccess = document.getElementById('user-modal-tab-login-success'),
            modalTabSignupSuccess = document.getElementById('user-modal-tab-signup-success'),
            usernameEl = null,
            modalTabPurchase = document.getElementById('user-modal-tab-purchase'),
            activeTab = null,
            screenName = TNCMS.User.getScreenName(),
            searchParams = new URLSearchParams(document.location.search);

        if(modalTabSignupSuccess){ usernameEl = modalTabSignupSuccess.querySelector('.username') }

        if(activeTab){
            if(!activeTab.hasAttribute('data-email-verification')){ 
                requireEmailVerification = false;
            }
        }

        if(__tnt.subscription.access.checkedAccess){
            TNCMS.Access.revalidateAccess(function(oResp){
            
            var modalToggleLogin = document.getElementById('user-modal-toggle-login'),
                modalToggleSignup = document.getElementById('user-modal-toggle-signup'),
                modalTabOffers = document.getElementById('modal-offers-tab'),
                modalTabSignupSuccess = document.getElementById('user-modal-tab-signup-success'),
                modalEl = document.getElementById('access-offers-modal');

            /* success */
                __tnt.subscription.a.push(function(oResp){
                    __tnt.user.loginSuccess = true;
                });

                __tnt.subscription.a.push(function(oResp){
                    var n=0,
                        content = document.getElementById('asset-content'),                
                        toHide = null;

                    if (!oResp.required) {
                        if(content){ toHide = content.querySelectorAll('.subscriber-hide,.subscriber-remove,.subscriber-only') }

                        if(toHide && toHide.length){
                            for( var elh = 0; elh < toHide.length; elh++ ){
                                toHide[elh].hidden = false;
                            }
                        }
                    }

                    if(content){ content.hidden=false }
                });

                __tnt.subscription.a.push(function(oResp){

                    if(__tnt.user.purchasedAccess){
                        // User purchased access and will be shown the purchase success dialog by spreedly
                    } else if(__tnt.user.firstLogin && modalTabSignupSuccess){
                        if(usernameEl && screenName){ 
                            usernameEl.innerText = " " + screenName; 
                        }

                        __tnt.subscription.access.showTab(modalTabSignupSuccess, 'access-offers-modal', 'access-methods' );

                    }else if(__tnt.user.manualLogin){
                        var loginNotification = document.createElement('div');
                            loginNotification.id = "login-success-notification";
                            loginNotification.classList.add("notification");
                            loginNotification.classList.add("alert");
                            loginNotification.classList.add("alert-dismissable");
                            loginNotification.classList.add("alert-success");
                            loginNotification.classList.add("hidden-print");
                            loginNotification.setAttribute("data-message","user logged in");
                            loginNotification.innerHTML = '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button> <strong><i class="fas tnt-check"></i>&nbsp; Thank you, ' + screenName + '</strong> you are now logged in.';
                            
                            loginNotification.addEventListener('click',function(ev){
                                document.getElementById('login-success-notification').remove();
                            });
                            
                        document.body.appendChild(loginNotification);

                        $(modalEl).find('.modal').modal('hide');

                    }
                });

                if(!oResp.required){
                    for(i=0; i<__tnt.subscription.a.length; i++){
                        if(typeof(__tnt.subscription.a[i])=="function"){
                            __tnt.subscription.a[i](oResp);
                        }
                    }
                }else{
                    for(n=0; n<__tnt.subscription.a.length; n++){
                        if(typeof(__tnt.subscription.d[n])=="function"){
                            __tnt.subscription.d[n](oResp);
                        }
                    }

                    __tnt.subscription.access.showTab(modalTabOffers, 'access-offers-modal', 'access-methods');

                }

            }, function(oResp){
            /* fail */
            console.log("Failed to revalidate");
            });
        // access checked
        } else if(__tnt.user.firstLogin && modalTabSignupSuccess){
            if(usernameEl && screenName){
                usernameEl.innerText = " " + screenName; 
            }

            __tnt.subscription.access.showTab(modalTabSignupSuccess, 'access-offers-modal', 'access-methods' );
        
        } else {
            var modalWrapper = document.getElementById('access-offers-modal'),
                modalEl = null;
                if(modalWrapper){ modalEl = modalWrapper.querySelector('.modal') }

            if(!__tnt.user.purchasedAccess){
                $(modalEl).modal('hide');
            }
        }
       
        if(searchParams.has('referer_url') && !__tnt.subscription.access.displayReceipt){
            document.location = searchParams.get('referer_url');
        }
    }// access checked
}

__tnt.subscription.populatePromoModal = function(){
    var modalEl = document.getElementById('access-offers-modal'), 
        promoTab = document.getElementById('user-modal-tab-promo'),
        promoForm = document.getElementById('service-promo-fetch'),
        promoSpinner = null,
        promoOutput = document.getElementById('service-promo-output'),
        promoCode = promoForm.elements.promoCode.value,
        formAction = promoForm.getAttribute('action'),
        errorEl = document.getElementById('service-promo-error'),
        currency = "USD";

    if(promoSpinner){ promoSpinner.style = "" }
    if(promoForm && promoForm.getAttribute('data-currency')){ currency = promoForm.getAttribute('data-currency') }
    if(errorEl){ errorEl.classList.add('hide') }

    fetch(formAction + "?promoCode=" + promoCode, {
        method: 'get',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }).then(function(oResp){

        if (oResp.status !== 200) {
            __tnt.log('Error. Status Code: ' + response.status);
            return;
        }

        oResp.json().then(function(data) {
            var offerGroup = { offers: [] },
                siteNameEl = document.head.querySelector('meta[property="og:site_name"]'),
                siteName = "",
                purchaseTab = document.getElementById('user-modal-tab-purchase'),
                purchaseForm = document.getElementById('form-super-purchase'),
                errorTab = document.getElementById('modal-error-tab'),
                previewMode = false,
                rateEl = document.getElementById('user-purchase-rate'),
                outputEl = document.getElementById('service-promo-output'),
                headerEl = modalEl.querySelector('.offer-group-title'),
                templateEl = modalEl.querySelector('template.template-promo-service'),
                limit = 6,
                context = "promo",
                legacyContext = "promo";

                if(siteNameEl){ siteName = siteNameEl.content }
                if(modalEl.hasAttribute('data-preview')){ previewMode = true }
                if(errorEl){ errorEl.classList.add('hide') }

            // Convert legacy promo data format to offer
            if(data && data.length){
                data.forEach(function(promo){
                    if(promo.rates){
                        var serviceId = promo.service_id,
                            serviceName = promo.name;

                        promo.rates.forEach(function(rate){
                            var rateId = rate.id,
                                rateName = rate.name,
                                ratePrice = rate.price / 100,
                                image = null;

                                if(rate.graphic){ image = rate.graphic.image }

                            /* Create a dummy offer group as a shim */
                             offerGroup.offers.push({
                                "id": rateId,
                                "service_id": serviceId,
                                "rate_id": rateId,
                                "title": rateName,
                                "description": data.description,
                                "rate": "$" + Number(rate.price)/100 + " for " + rate.duration + " days",
                                "image": image,
                                "limits": "",
                                "billing_cycle": "Every " + rate.duration + " days",
                                "savings": "",
                                "featured": 0,
                                "featured_message": "",
                                "button_text": "Subscribe",
                                "link_url": rate.rate_purchase_url,
                                "link_js": "",
                                "enabled": 1,
                                "start_date": null,
                                "end_date": null,
                                "service_name": serviceName,
                                "rate_name": rateName,
                                "rate_price": rate.price, // unformatted
                                "rate_duration": rate.duration,
                                "rate_currency": currency
                            });
                        });
                    }

                });
            }
            offerGroup.name = "Promo";
            offerGroup.coupon_code = promoCode;

            if(data.length == 1){
                if(data[0].rates && data[0].rates.length == 1){
                    var productData = {},
                        logData = [],
                        fakeOffer = offerGroup.offers[0],
                        serviceName = fakeOffer.service_name,
                        serviceId = fakeOffer.service_id,
                        rateId = fakeOffer.rate_id,
                        rateName = fakeOffer.rate_name;

                    /* 
                     * Initialize Product Data
                     */
                    productData = {
                        'product_id': fakeOffer.service_id,             // subscription service id
                        'sku': fakeOffer.rate_id,                       // offer rate id
                        'name': fakeOffer.service_name,                 // title of the service
                        'url': fakeOffer.purchase_url,                  // the current url being displayed? Or is this supposed to be the offer's link url?
                        'position': 1,                                  // same as the position count in the impression
                        'image_url': fakeOffer.image,                   // image returned by the offer
                        'quantity': 1,                                  // always 1 for subscriptions
                        'coupon': promoCode,                            // coupon code entered by the user
                        'category': "Promo",                            // name of the offer group - hard coded to promo?
                        'price': Number(fakeOffer.rate_price) / 100,    // price of the offer in dollar.cents
                        'variant': fakeOffer.title,                     // name of the rate and not the rate id?
                        'brand': siteName,                              // the sites brand label
                        'currency': currency,                           // the currency code of the money used for this item
                        'viewed_from': "Promo",                         // type of view of the product internal use
                        'legacy_context': "Promo"                       // Legacy view data Article/Block/Dashboard/Promo
                    }

                    logData.push({
                        impressionData: {
                            'id':fakeOffer.rate_id,
                            'name':fakeOffer.service_name,
                            'price':Number(fakeOffer.rate_price) / 100,
                            'variant': fakeOffer.rate,
                            'brand':siteName,
                            'category': 'subscription',
                            'list':'Block',
                            'variant': fakeOffer.title,
                            'position': 0
                        },
                        offerData: fakeOffer,
                        offerGroupId: offerGroup.id || promoCode,
                        offerGroupName: offerGroup.name,
                        offerContext: "promo",
                        paywallDisplay: 'modal',
                        productData: productData
                    });

                    __tnt.subscription.offers.logImpression(logData);

                    // populate the purchase form
                    __tnt.subscription.offers.purchaseDetails(fakeOffer, siteName, serviceName, serviceId, rateId, rateEl, rateName, purchaseTab, purchaseForm, previewMode, productData).then(function(){
                        // switch to the purchase form
                        __tnt.subscription.access.showTab(purchaseTab, "access-offers-modal", "access-methods", true);

                        purchaseForm.dispatchEvent(tntPurchase);
                        purchaseForm.elements.promoCode.value = promoCode;
                    }).catch(function(e){
                        console.log(e);

                        __tnt.subscription.access.showTab(errorTab, "access-offers-modal", "access-methods", true);
                        $(modalEl).find('.modal').modal();

                    }).finally(function(){
                        __tnt.subscription.access.showTab(purchaseTab, 'access-offers-modal', 'access-methods');
                        $(modalEl).find('.modal').modal();
                    });
                }
            } else if(data.length > 1) {

                __tnt.subscription.offers.populate(offerGroup, outputEl, headerEl, templateEl, limit, context, legacyContext, function(t, offer){
                    if(promoSpinner){
                        promoSpinner.style = "display:none";
                    }

                    t.querySelector('a.method-link').addEventListener('click',function(ev){
                        ev.preventDefault();

                        if(ev.target){
                            // Get product data from target element
                            var productData = JSON.parse(ev.target.getAttribute('data-product'));
                        }

                        if(ev.target.getAttribute('data-subscription-service')){
                            var serviceId = ev.target.getAttribute('data-subscription-service'),
                                serviceName = ev.target.getAttribute('data-name'),
                                rateId = ev.target.getAttribute('data-subscription-rate'),
                                rateEl = document.getElementById('user-purchase-rate'),
                                rateName = ev.target.getAttribute('data-subscription-rate-name');

                            __tnt.subscription.offers.purchaseDetails(offer, siteName, serviceName, serviceId, rateId, rateEl, rateName, purchaseTab, purchaseForm, previewMode, productData).then(function(){
                                __tnt.subscription.access.showTab(purchaseTab, "access-offers-modal", "access-methods", true);

                                purchaseForm.dispatchEvent(tntPurchase);
                                purchaseForm.elements.promoCode.value = promoCode;
                            }).catch(function(e){
                                console.log(e);

                                __tnt.subscription.access.showTab(errorTab, "access-offers-modal", "access-methods", true);
                                $(modalEl).find('.modal').modal();
                            });
                        }
                    });

                }).then(function(data){
                    if(!previewMode){
                        __tnt.subscription.offers.logImpression(data);
                    }

                    __tnt.subscription.access.showTab(promoTab, 'access-offers-modal', 'access-methods');

                    // Size modal
                    if(offerGroup.offers.length <= 2){
                        modalEl.classList.add('modal-small');
                    }else{
                        modalEl.classList.remove('modal-small');
                    }

                    $(modalEl).find('.modal').modal();
                });
            } else {
                console.log("Promo Populate - No Results Found");

                if(promoSpinner){
                    promoSpinner.style = "display:none";
                }
                outputEl.innerHTML = "";
                errorEl.classList.remove('hide');

                __tnt.subscription.access.showTab(promoTab, 'access-offers-modal', 'access-methods');
                modalEl.classList.add('modal-small');

                $(modalEl).find('.modal').modal();
            }

        });

    }).catch(function(oErr){
        console.log("ERROR: Populate Promo", oErr);
    }).finally(function(){
    });
}
/* Populate the Offer Modal with an offerGroup */
__tnt.subscription.populateOfferModal = function(offerGroup, previewMode, context, legacyContext){
    var modalWrapper = document.getElementById('access-offers-modal'),
        modalEl = null,
        loginTab = document.getElementById('user-modal-tab-login'),
        offersTab = document.getElementById('modal-offers-tab'),
        templateEl = null,
        headerEl = null,
        descriptionEl = null,
        outputEl = null,
        accessMethods = document.getElementById('access-methods'),
        purchaseTab = document.getElementById('user-modal-tab-purchase'),
        purchaseForm = document.getElementById('form-super-purchase'),
        purchaseRateDisplay = document.getElementById('user-purchase-rate'),
        limit = 3,
        siteNameEl = document.head.querySelector('meta[property="og:site_name"]'),
        siteName = "",
        errorTab = document.getElementById('modal-error-tab');

    if(siteNameEl){ siteName = siteNameEl.content }
    if(modalWrapper){ modalEl = modalWrapper.querySelector('.modal') }
    if(offersTab){
        templateEl = offersTab.querySelector('template.template-promo-service');
        headerEl = offersTab.querySelector('.offer-group-title');
        descriptionEl = offersTab.querySelector('.offer-group-description');
        outputEl = offersTab.querySelector('.output');
    }

    if(offerGroup){
        if(headerEl && offerGroup.name){ headerEl.innerHTML = offerGroup.title }
        if(descriptionEl && offerGroup.message){
            descriptionEl.classList.add('padding-bottom');
            descriptionEl.innerHTML = offerGroup.message;
        }

        __tnt.subscription.offers.populate(offerGroup, outputEl, headerEl, templateEl, limit, context, legacyContext, function(t, offer){

            t.querySelector('a.method-link').addEventListener('click',function(ev){
                ev.preventDefault();

                if(ev.target){
                    /* Get product data from target element */
                    var productData = JSON.parse(ev.target.getAttribute('data-product'));
                    if(productData){
                        __tnt.subscription.offers.logClick(productData);
                        __tnt.subscription.offers.logDetails(productData);
                    }
                }

                if(ev.target.getAttribute('data-subscription-service')){
                    var serviceId = ev.target.getAttribute('data-subscription-service'),
                        serviceName = ev.target.getAttribute('data-name'),
                        rateId = ev.target.getAttribute('data-subscription-rate'),
                        rateEl = document.getElementById('user-purchase-rate'),
                        rateName = ev.target.getAttribute('data-subscription-rate-name');

                    __tnt.subscription.offers.purchaseDetails(offer, siteName, serviceName, serviceId, rateId, rateEl, rateName, purchaseTab, purchaseForm, previewMode, productData).then(function(){
                        __tnt.subscription.access.showTab(purchaseTab, "access-offers-modal", "access-methods", true);
                        $(modalEl).modal('show');
                        purchaseForm.dispatchEvent(tntPurchase);
                    }).catch(function(e){
                        console.log(e);

                        __tnt.subscription.access.showTab(errorTab, "access-offers-modal", "access-methods", true);
                        $(modalEl).modal('show');
                    });

                }else{
                    if(ev.target.href!="javascript:void(0)"){
                        document.location = ev.target.href;
                    }

                } // end service check
            });

        }).then(function(data){
            __tnt.subscription.offers.logImpression(data);

        }).catch(function(e){ 
            console.log(e);

            $(modalEl).modal('hide');
        });
    }
}

/*
 * Take offers passed and output them on an element in-page
 */
__tnt.subscription.offers.populate = function (offerGroup, outputEl, headerEl, templateEl, limit, context, legacyContext, callback) {
try{
    var siteNameEl = document.head.querySelector('meta[property="og:site_name"]'),
        siteName = "",
        pos = 1,
        populatePromises = [],
        purchaseType = 'standard';

    if (templateEl && templateEl.dataset.type) { purchaseType = templateEl.dataset.type }
    if (siteNameEl) { siteName = siteNameEl.content }

    if (offerGroup) {
        var offers = offerGroup.offers;

        if (limit) { offers = offers.slice(0, limit) }

        /* Populate Offer Header */
        if (headerEl) {
            var titleEl = headerEl.querySelector('.offer-group-title'),
                messageEl = headerEl.querySelector('.offer-group-description');

            if (offerGroup.title && titleEl) { titleEl.innerHTML = offerGroup.title }
            if (offerGroup.message && messageEl) { messageEl.innerHTML = offerGroup.message }
        }

        /* Populate Offer Output */
        if (outputEl && offers) {

            // Clear the output element
            outputEl.innerHTML = "";

            // Single offer class
            if (offers.length < 2) {
                outputEl.classList.add("single-offer");
            } else {
                outputEl.classList.remove("single-offer");
            }

            /** Loop through offers */
            offers.forEach(function (offer) {
                var description = offer.description,
                    buttonText = "Get Started",
                    buttonClass = "method-link btn btn-danger btn-lg no-services",
                    servicePanelClasses = "promotion-service subscription-service panel panel-default",
                    image = null,
                    imageLayout = 'horizontal',
                    productData = null,
                    impressionData = null,
                    cancelPolicy = "",
                    productId = offer.service_id || offer.id,
                    productPrice = (offer.rate_price) ? Number(offer.rate_price) / 100 : undefined,
                    userOwnsService = false,
                    price = 0;
                    
                if(__tnt.user.authToken && __tnt.user.services && __tnt.user.services.split(',').includes(offer.service_id)){
                    userOwnsService = true;
                }
                
                if (offer.rate_price){ price = Number(offer.rate_price) / 100 }

                if(offerGroup.offers_are_access_methods){
                    buttonClass = "method-link btn btn-primary btn-lg";
                    cancelPolicy = "";
                }

                /* Initialize Product Data */
                if (productId && !userOwnsService) {
                    impressionData = {
                        'id': offer.id,
                        'name': offer.title,
                        'price': price,
                        'variant': offer.rate,
                        'brand': siteName,
                        'category': 'subscription',
                        'list': legacyContext,
                        'position': pos
                    }

                    if (offer.service_id) { 
                        impressionData.id = offer.service_id;
                    }

                    productData = {
                        'product_id': productId,                // subscription service id or offer id
                        'sku': offer.rate_id,                   // offer rate id
                        'name': offer.service_name,             // title of the service
                        'url': offer.link_url,                  // the current url being displayed
                        'purchase_type': purchaseType,          // Standard or gift purchase
                        'position': pos,                        // same as the position count in the impression
                        'image_url': offer.image,               // image returned by the offer
                        'quantity': 1,                          // always 1 for subscriptions
                        'coupon': offerGroup.coupon_code,       // coupon code entered by the user
                        'category': offerGroup.name,            // name of the offer group
                        'price': productPrice,                  // price of the offer
                        'variant': offer.rate,                  // name of the rate and not the rate id?
                        'brand': siteName,                      // the sites brand label
                        'currency': offer.rate_currency,        // the currency code of the money used for this item
                        'viewed_from': context,                 // type of view of the product internal use
                        'legacy_context': legacyContext         // Legacy view data Article/Block/Dashboard
                    }
                }

                if (offer.button_text) { buttonText = offer.button_text }
                // Present subscribers with a manage link for their existing service when they are presented an offer for a service they already own, unless this is a gift
                if(purchaseType != 'gift' && userOwnsService){
                    var templateContent = document.importNode(templateEl.content, true),
                        buttonTextEl = null;

                    if(templateContent){
                        buttonTextEl = templateContent.querySelector('a .user-owns-this-service');

                        if(buttonTextEl){ buttonText = buttonTextEl.innerText }
                    }

                    servicePanelClasses += " user-owns-this-service";
                    offer.title = offer.service_name;
                    offer.rate = "";
                    offer.limits = "";
                    offer.billing_cycle = "";
                    offer.savings = "";
                    offer.rate_id = null;
                    offer.link_js = null;
                    offer.link_url = document.location.origin + "/users/admin/service/";
                    offer.service_id = null;
                    buttonText = buttonText;
                    offer.featured_message = "";
                    description = "";
                }

                // Image Insertion
                if (offer.image) {
                    image = '<figure class="photo layout-' + imageLayout + ' padding-bottom"><div class="image"><img class="img-responsive full default" src="' + offer.image + '" alt="' + offer.title + '"/></div></figure>';
                }

                /* Elements to modify within the template element before it is added to the DOM and displayed to the user. */
                var elements = [
                    {
                        'element': '.subscription-service.panel',
                        'attr': [
                            {
                                'attr': 'class',
                                'value': servicePanelClasses,
                            },
                            {
                                'attr': 'data-offer-id',
                                'value': offer.id
                            }
                        ]
                    }, {
                        'element': '.method-header',
                        'html': offer.title
                    }, {
                        'element': '.method-image',
                        'html': image
                    }, {
                        'element': '.offer-rate',
                        'html': offer.rate
                    }, {
                        'element': '.offer-cycle',
                        'html': offer.billing_cycle
                    }, {
                        'element': '.offer-savings',
                        'html': offer.savings
                    }
                ];

                if(offer.limits != ""){
                    elements.push({
                        'element': '.offer-limits',
                        'html': offer.limits
                    });
                }

                if (offerGroup.offers_are_access_methods && description){
                    elements.push({
                        'element': '.offer-details',
                        'html': description
                    });
                } else if (description) {
                    elements.push({
                        'element': '.method-description',
                        'html': "<h4>What's Included?</h4><div class=\"text-wrapper\">" + description + "</div>"
                    });
                }

                /* Link Handling */
                if (offer.service_id != null && offer.rate_id != null) {
                    elements.push({
                        'element': 'a.method-link',
                        'html': buttonText,
                        'attr': [
                            {
                                'attr': 'class',
                                'value': buttonClass
                            }, {
                                'attr': 'data-subscription-service',
                                'value': offer.service_id
                            }, {
                                'attr': 'data-subscription-rate',
                                'value': offer.rate_id
                            }, {
                                'attr': 'data-subscription-rate-name',
                                'value': offer.rate
                            }, {
                                'attr': 'data-name',
                                'value': offer.title
                            }, {
                                'attr': 'data-product',
                                'value': JSON.stringify(productData)
                            }
                        ]
                    });
                } else if (offer.link_js) {
                    elements.push({
                        'element': 'a.method-link',
                        'html': buttonText,
                        'attr': [
                            {
                                'attr': 'class',
                                'value': buttonClass
                            }, {
                                'attr': 'href',
                                'value': 'javascript:void(0)'
                            }, {
                                'attr': 'onClick',
                                'value': offer.link_js
                            }, {
                                'attr': 'data-name',
                                'value': offer.title
                            }
                        ]
                    });
                } else {
                    elements.push({
                        'element': 'a.method-link',
                        'html': buttonText,
                        'attr': [
                            {
                                'attr': 'class',
                                'value': buttonClass
                            }, {
                                'attr': 'href',
                                'value': offer.link_url
                            }, {
                                'attr': 'data-name',
                                'value': offer.title
                            }
                        ]
                    });
                }

                /* Featured Message */
                if (offer.featured_message) {
                    elements.push({
                        'element': '.method-featured',
                        'html': '<span class="featured-icon"></span><span class="featured-message">' + offer.featured_message + '</span>',
                        'attr': [
                            {
                                'attr': 'class',
                                'value': 'method-featured featured-' + offer.featured
                            }
                        ]
                    });
                }


                var tPromise = new Promise(function (resolve, reject) {
                    // Write template
                    __tnt.template(
                        templateEl,     // template element
                        outputEl,       // output element
                        false,          // add items from start of output
                        elements,       // elements in template to modify
                        function (t) {    // callback to run after template is added
                            // hide description if empty
                            var descriptionEl = t.querySelector('.method-description');
                            if (descriptionEl && descriptionEl.innerHTML == "") { descriptionEl.hidden = true }
                            
                            // remove featured header if empty
                            var featuredEl = t.querySelector('.method-featured');
                            if (featuredEl && featuredEl.innerHTML == ""){ featuredEl.remove() }
                            
                            // remove cancel if empty
                            var cancelEl = t.querySelector('.offer-cancel');
                            if (cancelEl && cancelEl.innerHTML == ""){ cancelEl.remove() }

                            var offerDisplay = "inline";
                            if (outputEl.getAttribute('data-paywall-display')){ offerDisplay = outputEl.getAttribute('data-paywall-display') }

                            resolve({
                                template: t,
                                impressionData: impressionData,
                                offerData: offer,
                                offerGroupId: offerGroup.id || offerGroup.coupon_code,
                                offerGroupName: offerGroup.name,
                                offerContext: context,
                                paywallDisplay: offerDisplay,
                                productData: productData
                            });

                            // check to see if there are any site customizations to offers needed
                            if(typeof(__tnt.subscription.offers.customizeOffers) === "function"){ __tnt.subscription.offers.customizeOffers(t, offer) }

                            // run the passed callback function if defined
                            if (typeof (callback) === 'function') { callback(t, offer) }
                        }
                    );
                });

                pos++;
                populatePromises.push(tPromise);

            }); // end offer loop
        }
    } else { throw new Error("No offerGroup found") }

    return Promise.all(populatePromises);
} catch(error) { console.warn(error) } };

__tnt.subscription.offers.purchaseDetails = function (offer, siteName, serviceName, serviceId, rateId, rateEl, rateName, purchaseTab, purchaseForm, previewMode, productData) {
try{
    // Reset form
    if(purchaseForm){
        purchaseForm.reset(); 
    } else { throw new Error("purchaseForm element not found") }

    var fetchUrl = "/tncms/subscription/services/",
        searchParams = new URLSearchParams();

    if(serviceId){ searchParams.append('service_id', serviceId) }
    if(rateId){ searchParams.append('rate_id', rateId) }
    if(productData && productData.coupon){ searchParams.append('promoCode', productData.coupon) }

    // Fetch details
    var fetchPromise = new Promise(function (resolve, reject) {
        fetch(fetchUrl + "?" + searchParams.toString(), {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(function (oResp) {
            if (oResp.ok) {
                oResp.json().then(function (oData) {

                    var oRate = null,
                        iRate = 0,
                        sPrice = rateName,
                        sDurationUnit = "days";

                    // Set form element values
                    if(purchaseForm){
                        purchaseForm.elements.service_id.value = serviceId;
                        purchaseForm.elements.rate_id.value = rateId;
                        purchaseForm.elements.rate_name.value = rateName;
                        purchaseForm.elements.rate_price.value = productData.price;
                    }

                    // Get rate information from response
                    if(oData[0] && oData[0].rates){ 
                        while (!oRate && iRate < oData[0].rates.length) {
                            if (oData[0].rates[iRate].id == rateId) {
                                oRate = oData[0].rates[iRate];
                                break;
                            }
                            iRate++;
                        }
                    }

                    // Rate not found. Collect from passed offer
                    if (oRate == null && offer != null) {
                        oRate = offer;

                        // Populate alternate keys
                        oRate.name = offer.rate_name;
                        oRate.duration = offer.rate_duration;
                        oRate.price = offer.rate_price;
                        oRate.rate_purchase_url = offer.link_url;
                    }

                    // Set rate string
                    try {
                        if (oRate.duration > 7) purchaseForm.querySelector('.service-renews').hidden = false;
                    } catch (e) { /* No renewal */ }

                    if (oRate.duration == 1) { sDurationUnit = "day" }
                    sPrice = "$" + (oRate.price / 100) + " for " + oRate.duration + " " + sDurationUnit;

                    // Prep rate display output
                    var purchaseRateTemplate = rateEl.querySelector('template'),
                        purchaseRateOutput = rateEl.querySelector('.output'),
                        elements = [
                            {
                                'element': '.rate-name',
                                'html': offer.title
                            }, {
                                'element': '.rate-price',
                                'html': sPrice
                            }, {
                                'element': '.service-description',
                                'html': offer.description
                            }, {
                                'element': 'a.service-link',
                                'attr': [{
                                    'attr': 'href',
                                    'value': offer.link_url
                                }]
                            }
                        ];

                    // Clear rate display output
                    purchaseRateOutput.innerHTML = "";

                    // Build the purchase display and prep form
                    __tnt.template(purchaseRateTemplate, purchaseRateOutput, false, elements, function (t) {
                        t.querySelector('.rate-info').addEventListener('click', function (ev) {
                            var rateExpand = rateEl.querySelector('.service-rate .rate-info-expander');

                            rateEl.classList.toggle('expanded');
                            rateEl.querySelector('.service-rate .service-details').classList.toggle('hide');
                            rateExpand.querySelector('.collapsed').classList.toggle('hide');
                            rateExpand.querySelector('.expanded').classList.toggle('hide');
                        });

                        var locationFields = purchaseTab.querySelector('.delivery-location-required'),
                            bDeliveryRequired = false;

                        if (oData[0].delivery_required) { bDeliveryRequired = true }
                        if (locationFields && bDeliveryRequired) {
                            locationFields.hidden = false
                        } else if (locationFields) { locationFields.hidden = true }

                        // Log Click and Details
                        if (!previewMode) {
                            __tnt.subscription.offers.logClick(productData);
                            __tnt.subscription.offers.logDetails(productData);
                        }

                        __tnt.subscription.handleCart(oRate, serviceId, serviceName, siteName);

                        resolve();
                    });

                    // Adjust form for purchase type
                    __tnt.subscription.offers.purchaseType(purchaseForm, productData);

                    // Tax consideration
                    __tnt.subscription.offers.purchaseTax(oData, purchaseForm, productData);
                });
            } else {
                reject({ 'error': true, 'message': "An error occurred: Service " + serviceId + " may be disabled." });
            }
        }).catch(function (e) { reject({ 'error': e, 'message': "An error occurred: Service " + serviceId + " may be disabled." }) });

    });

    return fetchPromise;
} catch(error){ console.warn(error) }};

__tnt.subscription.offers.purchaseType = function (purchaseForm, productData) {
    try{
        if (productData && productData.purchase_type == 'gift') {
            // Swap to gift purchase
            purchaseForm.action = '/tncms/subscription/giftpurchase/';
            purchaseForm.dataset.purchaseType = 'gift';
            typeSwap(purchaseForm, '.purchase-standard', false);
            typeSwap(purchaseForm, '.purchase-gift', true);
            purchaseForm.dataset.authRequired = 'false';

            // Check user status and hide/disable the email field
            var purchaserEmail = document.getElementById('purchaser-email');
            var purchaserEmailInput = purchaserEmail.querySelector('input');

            if(purchaserEmail && purchaserEmailInput){
                if (TNCMS.User.isLoggedIn()) {
                    purchaserEmail.hidden = true;
                    purchaserEmailInput.disabled = true;
                } else {
                    purchaserEmail.hidden = false;
                    purchaserEmailInput.disabled = false;
                }
            }

            // Show form always
            purchaseForm.hidden = false;
            purchaseForm.classList.remove('not-logged-in-form-disabled');
        } else {
            // Swap to standard purchase
            purchaseForm.action = '/tncms/subscription/buy/';
            purchaseForm.dataset.purchaseType = 'standard';
            typeSwap(purchaseForm, '.purchase-standard', true);
            typeSwap(purchaseForm, '.purchase-gift', false);
            purchaseForm.dataset.authRequired = 'true';

            // Hide form when not logged in and federated auth is offered
            if (__tnt.user.authToken == null
                && purchaseForm.dataset.authFederated == 'true'
                && purchaseForm.dataset.continueWithEmail != 'true') {
                purchaseForm.hidden = true;
                purchaseForm.classList.add('not-logged-in-form-disabled');
            }
        }
    } catch(error){ console.warn(error) }

    function typeSwap(purchaseForm, target, show) {
        var container = purchaseForm.closest('.access-offers-wrapper');

        container.querySelectorAll(target).forEach(function (el) {
            var requiredItems = null;
            if (__tnt.user.authToken) { requiredItems = el.querySelectorAll('[data-required]:not([data-required-logged-out]');
            } else { requiredItems = el.querySelectorAll('[data-required]'); }

            if (show) {
                el.hidden = false;
                requiredItems.forEach(function (item) {
                    item.required = true;
                    item.disabled = false;
                });
            } else {
                el.hidden = true;
                requiredItems.forEach(function (item) {
                    item.required = false;
                    item.disabled = true;
                });
            }
        })
    }
}

__tnt.subscription.offers.purchaseTax = function (data, purchaseForm, productData) {
    var taxDetails = purchaseForm.closest('.access-offers-wrapper').querySelectorAll('.tnt-tax-detail'),
        taxSubTotal = purchaseForm.querySelector('.tax-subtotal'),
        taxTotal = purchaseForm.querySelector('.tax-total'),
        zipInput = purchaseForm.querySelector('[name="postal-postcode"]'),
        countrySelect = purchaseForm.querySelector('[name="postal-country"]'),
        stateSelect = purchaseForm.querySelector('[name="postal-state"]'),
        delZipInput = purchaseForm.querySelector('[name="postal_code"]'),
        delCountrySelect = purchaseForm.querySelector('[name="country"]'),
        delStateSelect = purchaseForm.querySelector('[name="region"]');

    // Clear previous processing and listeners
    try { 
        purchaseForm.querySelector('[data-tax-zip]').removeEventListener('input', __tnt.subscription.offers.taxListener);
        delete purchaseForm.querySelector('[data-tax-zip]').dataset.taxZip; 
    } catch(e){ console.log(e) }
    try {
        purchaseForm.querySelector('[data-tax-country]').removeEventListener('change', __tnt.subscription.offers.taxListener);
        delete purchaseForm.querySelector('[data-tax-country]').dataset.taxCountry;
    } catch(e){ console.log(e) }

    /** Purchase is taxable */
    if ((data[0].taxEngineCompanyCode || data[0].taxEngineProvider == 'canada')) {
        purchaseForm.dataset.taxable = 'true';
        purchaseForm.dataset.taxEngineCompanyCode = data[0].taxEngineCompanyCode;
        purchaseForm.dataset.taxEngineProvider = data[0].taxEngineProvider;

        // Set tax trigger inputs
        if (data[0].delivery_required) {
            zipInput = delZipInput,
            countrySelect = delCountrySelect,
            stateSelect = delStateSelect;
        } else if(!purchaseForm.hasAttribute('data-location-required')){
            // Disable the delivery fields
            delZipInput.disabled = true;
            delCountrySelect.disabled = true;
            delStateSelect.disabled = true;
        }

        // Remove gift purchase zip input
        var purchaserZip = document.getElementById('purchaser-zip');
        if (purchaserZip) purchaserZip.remove();

        // Enable tax fields
        zipInput.required = true;
        zipInput.disabled = false;
        countrySelect.disabled = false;
        stateSelect.disabled = false; 

        // Identify tax targets used in calculation
        zipInput.dataset.taxZip = 'true';
        countrySelect.dataset.taxCountry = 'true';

        // Do not allow spaces
        zipInput.addEventListener('keydown', function (event) {
            if (event.key === " ") event.preventDefault();
        });

        // Send for tax detail
        zipInput.addEventListener('input', __tnt.subscription.offers.taxListener);

        // Country select listen for changes
        countrySelect.addEventListener('change', __tnt.subscription.offers.taxListener);

        // Prime tax display
        taxSubTotal.innerHTML = "$" + productData.price.toFixed(2);
        taxTotal.innerHTML = '';

        // Reveal tax details
        taxDetails.forEach(function (el) {
            el.classList.remove('hidden');
        });
    } else {
        /** Purchase is not taxable */
        delete purchaseForm.dataset.taxable;
        delete purchaseForm.dataset.taxEngineCompanyCode;
        delete purchaseForm.dataset.taxEngineProvider;

        if(!purchaseForm.hasAttribute('data-location-required')){
            // Disable billing location
            zipInput.required = false;
            zipInput.disabled = true;
            countrySelect.disabled = true;
            stateSelect.disabled = true;
        }

        // If delivery is required enable delivery fields
        if (data[0].delivery_required) {
            delZipInput.disabled = false;
            delCountrySelect.disabled = false;
            delStateSelect.disabled = false;
        } else {   
            // Disable the delivery fields
            delZipInput.disabled = true;
            delCountrySelect.disabled = true;
            delStateSelect.disabled = true;
        }

        // If gift collect add purchaser zip input
        if (productData.purchase_type == 'gift') {
            // Prep zip input
            var purchaserZip = purchaseForm.querySelector('input[name=purchaser_zip]'),
                zipTemplate = document.getElementById('purchaser-zip-template'),
                zipClone = zipTemplate.content.cloneNode(true);
            
            // Place purchaser zip input
            if (purchaserZip == null) {
                document.getElementById('purchaser-info').append(zipClone);
            }
        }
        
        // Hide tax details
        taxDetails.forEach(function (el) {
            if(!el.classList.contains('tnt-required')){
                el.classList.add('hidden');
            }
        });
    }
}

__tnt.subscription.offers.taxListener = function (event) {
    var purchaseForm = event.target.closest('form'),
        zipInput = purchaseForm.querySelector('[data-tax-zip]'),
        countrySelect = purchaseForm.querySelector('[data-tax-country]');

    if (zipInput.value.length == 5 && countrySelect.value != 'CA'
        || zipInput.value.length == 6 && countrySelect.value == 'CA') {
        __tnt.subscription.offers.calculateTax(purchaseForm, zipInput, countrySelect); 
    } else {
        purchaseForm.querySelector('.tax-total').innerHTML = '';
    }
}

__tnt.subscription.offers.calculateTax = function (purchaseForm, zipInput, countrySelect) {
    if (typeof purchaseForm === 'undefined') return;
    if (typeof zipInput === 'undefined') {
        if (!purchaseForm.querySelector('[data-tax-zip]')) return;
        zipInput = purchaseForm.querySelector('[data-tax-zip]');
    }
    if (typeof countrySelect === 'undefined') {
        if (!purchaseForm.querySelector('[data-tax-country]')) return;
        countrySelect = purchaseForm.querySelector('[data-tax-country]');
    }

    // Submit request for taxes
    let endpoint = '/tncms/tax/transaction_tax/?',
        params = {
            'amount': purchaseForm.querySelector('[name="rate_price"]').value,
            'client': purchaseForm.dataset.taxEngineProvider,
            'company_code': purchaseForm.dataset.taxEngineCompanyCode,
            'location': {
                'country': countrySelect.value,
                'postal_code': zipInput.value
            }
        };

    // Structure params
    for (key in params) {
        if (typeof params[key] === 'object') {
            for (const property in params[key]) {
                endpoint += `&${key}[${property}]=${params[key][property]}`;
            }
        } else {
            endpoint += `&${key}=${params[key]}`;
        }
    };

    // Fetch tax information
    fetch(__tnt.scrubUrl(endpoint))
        .then(function (response) {
            if (response.status !== 200) {
                __tnt.log('Tax Error. Status Code: ' + response.status);
                sessionStorage.setItem('__tnt.service.rate.tax', 'N/A');
                sessionStorage.setItem('__tnt.service.rate.total', 'N/A');
                return;
            }
            response.json().then(function (result) {
                if (result.success) {
                    purchaseForm.querySelector('.tax-total').innerHTML =  '$' + result.data.taxCalculated.toFixed(2);
                    sessionStorage.setItem('__tnt.service.rate.tax', result.data.taxAssessed);
                    sessionStorage.setItem('__tnt.service.rate.total', result.data.taxCalculated);
                } else {
                    purchaseForm.querySelector('.tax-total').innerHTML = '';
                    sessionStorage.removeItem('__tnt.service.rate.tax');
                    sessionStorage.removeItem('__tnt.service.rate.total');
                }
            });
        })
        .catch(function (err) {
            __tnt.log("Fetch Error: ", err);
        });
}

document.addEventListener("DOMContentLoaded", function(event) {
    var modalEl = document.getElementById('access-offers-modal'), 
        modalToggleLogin = document.getElementById('user-modal-toggle-login'),
        modalToggleSignup = document.getElementById('user-modal-toggle-signup'),
        modalTabOffers = document.getElementById('modal-offers-tab'),
        modalTabLogin = document.getElementById('user-modal-tab-login'),
        modalTabSignup = document.getElementById('user-modal-tab-signup'),
        modalTabSignupSuccess = document.getElementById('user-modal-tab-signup-success'),
        modalTabPurchase = document.getElementById('user-modal-tab-purchase'),
        modalTabPurchaseSuccess = document.getElementById('user-modal-tab-purchase-success'),
        modalLinks = document.getElementById('offers-modal-user-links'),
        modalTabForgot = document.getElementById('user-modal-tab-forgot'),
        modalTabInstant = document.getElementById('user-modal-tab-instant-login'),
        accessMethods = document.getElementById('access-methods'),
        eedition = document.getElementById('eedition-wrapper'),
        logoutForm = document.getElementById('logout'),
        loginForm = document.getElementById('access-modal-login-form'),
        signupForm = document.getElementById('user-register-form'),
        promoOpen = document.getElementById('offer-promo-search'),
        promoTab = document.getElementById('user-modal-tab-promo'),
        promoForm = document.getElementById('service-promo-fetch'),
        promoSpinner = null,
        promoOutput = document.getElementById('service-promo-output'),
        thirdPartyLoginForm = document.getElementById('thirdPartyLogin'),
        loginLinks = null;

    if(modalEl){
        /* Subscribe Link */
        document.getElementById('user-modal-purchase-toggle').addEventListener('click',function(ev){
            if(ev.target.getAttribute('data-offer')){ tntOfferPopulateModal.offerId = ev.target.getAttribute('data-offer') }
            if(ev.target.getAttribute('data-preview-mode')){ tntOfferPopulateModal.previewMode = ev.target.getAttribute('data-preview-mode') }
            if(ev.target.getAttribute('data-context')){ tntOfferPopulateModal.context = ev.target.getAttribute('data-context') }
            if(ev.target.getAttribute('data-legacy-context')){ tntOfferPopulateModal.legacyContext = ev.target.getAttribute('data-legacy-context') }

            modalTabOffers.dispatchEvent(tntOfferPopulateModal);
        
            __tnt.subscription.access.showTab(modalTabOffers, 'access-offers-modal', 'access-methods');
        });
    
        /* Signup Link */
        document.getElementById('user-modal-signup-toggle').addEventListener('click',function(ev){
            signupForm.dispatchEvent(tntSignup);
            __tnt.subscription.access.showTab(modalTabSignup, 'access-offers-modal', 'access-methods');
        });

        /* Login Modal */
        loginLinks = modalEl.querySelectorAll('.login-toggle')
        if(loginLinks){ loginLinks.forEach(function(el){
            el.addEventListener('click', function(ev){
                ev.preventDefault();

                __tnt.subscription.access.showTab(modalTabLogin, 'access-offers-modal', 'access-methods');
            });
        }); }
        document.getElementById('user-modal-login-toggle').addEventListener('click',function(ev){
            ev.preventDefault();

            __tnt.subscription.access.showTab(modalTabLogin, 'access-offers-modal', 'access-methods');
        });

        if(modalLinks && modalLinks.querySelector('a.login-toggle')){
            modalLinks.querySelector('a.login-toggle').addEventListener('click',function(ev){
                ev.preventDefault();

                __tnt.subscription.access.showTab(modalTabLogin, 'access-offers-modal', 'access-methods');
            });
        }
        
        /* Forgot Modal */
        document.getElementById('open-modal-forgot').addEventListener('click',function(ev){
            ev.preventDefault();
            
            // Copy username from login form
            try {
                document.getElementById('forgot-username').value = document.getElementById('user-username').value;
            } catch(e) {}

            __tnt.subscription.access.showTab(modalTabForgot, 'access-offers-modal', 'access-methods');
        });
        
        /* Instant Login Modal */
        document.getElementById('open-modal-instant-login').addEventListener('click',function(ev){
            ev.preventDefault();

            __tnt.subscription.access.showTab(modalTabInstant, 'access-offers-modal', 'access-methods');
        });

        var backButtons = modalEl.querySelectorAll('.tab-back');
        if(backButtons){

           Array.from(backButtons).forEach(function(el) {
               el.addEventListener('click',function(ev){
                   var backTab = modalTabOffers;

                   if (el.closest('#user-modal-tab-purchase')) {
                       __tnt.subscription.offers.logProductRemoved();
                   }

                   if(__tnt.subscription.access.previousTab.length){ 
                       backTab = __tnt.subscription.access.previousTab[__tnt.subscription.access.previousTab.length - 1];
                       __tnt.subscription.access.previousTab.pop();
                       
                       __tnt.subscription.access.showTab(backTab, 'access-offers-modal', 'access-methods', true);
                   }
               });
           });
        }
        
        if(eedition){
            if(modalTabSignupSuccess){
                var signupDismissButton = modalTabSignupSuccess.querySelector('.modal-footer a[data-dismiss="modal"]'),
                    signupCloseButton = modalTabSignupSuccess.querySelector('.modal-header button[data-dismiss="modal"]'),
                    purchaseDismissButton = modalTabPurchaseSuccess.querySelector('.modal-footer a[data-dismiss="modal"]'),
                    purchaseCloseButton = modalTabPurchaseSuccess.querySelector('.modal-header button[data-dismiss="modal"]');

                signupDismissButton.addEventListener('click',function(){ location.reload() });
                signupCloseButton.addEventListener('click',function(){ location.reload() });
                purchaseDismissButton.addEventListener('click',function(){ location.reload() });
                purchaseCloseButton.addEventListener('click',function(){ location.reload() });

            }
        }
    }

    /* needs to allow opening new modal, close any other existing modals, allow re-opening services modal if it was closed, or open it if it wasn't */
    if(modalLinks && modalLinks.querySelector('.user-modal-open')){
        modalLinks.querySelector('.user-modal-open').addEventListener('click',function(ev){
            ev.preventDefault();

        });
    }

    /* If the modal's offer tab isn't populated, blocks can request to populate it */
    if(modalTabOffers){
        modalTabOffers.addEventListener('tnt-offer-populate-modal', function(oEv){
            var sOfferId = oEv.offerId,
                previewMode = oEv.previewMode,
                context = oEv.context,
                legacyContext = oEv.legacyContext;

            function handleError(e){
                console.error(e);

                var outputEl = modalTabOffers.querySelector('.output'),
                    errorTemplate = modalTabOffers.querySelector('template.promo-error-template');

                outputEl.innerHTML = "";
                __tnt.template(errorTemplate, outputEl, false, null, function (t) { });
            }

            if(oEv.offerId && sOfferId != ""){
                if(!accessMethods.querySelector('.subscription-service')){
                    TNCMS.Access.getOfferGroups().then(function(data){
                        var offerGroup= data[sOfferId];

                        try {
                            if(!offerGroup){
                                var offerIter = 0;

                                while(!offerGroup && offerIter<Object.keys(data).length){
                                    if(Object.keys(data)[offerIter] != "undefined"){
                                        offerGroup = data[Object.keys(data)[offerIter]];
                                        console.log("ERROR: Populate Offer Modal - No valid offer group found, using group " + offerGroup.id);
                                    }

                                    offerIter++;
                                }
                                if(!offerGroup){ throw new Error("Populate Offer Modal - no valid offer group found - check that the offer is valid and assigned to an active access rule") }

                                if(offerGroup.offers.length <= 2){
                                    modalEl.classList.add('modal-small');
                                }else{
                                    modalEl.classList.remove('modal-small');
                                }
                            }

                            __tnt.subscription.populateOfferModal(offerGroup, previewMode, context, legacyContext);
                        } catch(e){ handleError(e) }
                    }).catch(function(e){ handleError(e) });
                }
            }
        });
    }

    if(loginForm){ loginForm.addEventListener('submit', function(oEv){
        oEv.preventDefault();

        var loginForm = document.getElementById('access-modal-login-form'),
            errorEl = null;

        if(loginForm){
            var formBody = new FormData(loginForm),
                errorEl = loginForm.querySelector('.critical-error'),
                rememberMe = '';

            fetch('/tncms/auth/login',{
                method: 'POST',
                body: formBody,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then(function(oResp){
                if(errorEl){ errorEl.classList.add('hide') }

                if ( oResp.ok ) {
                    __tnt.user.manualLogin = true;
                }
                else {
                    throw new Error('Login failure - '+ oResp.statusText);
                }    
            }).catch(function(oErr){
                console.log(oErr);
                    
                if(errorEl){ errorEl.classList.remove('hide') }
            }).finally(function(){
                if(eedition){
                    location.reload();
                }

                __tnt.user.updateLoggedIn();
            });

        }
    }) }

    if(signupForm){
        signupForm.addEventListener('submit', function(oEv){
            oEv.preventDefault();
            
            var oForm = document.getElementById('user-register-form');
            
            if (oForm) {
                // Gather all form data
                var formBody = new FormData(oForm),
                    payload = new URLSearchParams(formBody),
                    errorEl = oForm.querySelector('.critical-error'),
                    submitButton = oForm.querySelector('button[type=submit]');
                
                fetch('/tncms/auth/signup', {
                    method: 'POST',
                    body: payload,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(function(oResp){
                    if(errorEl){ errorEl.classList.add('hide') }

                    if ( oResp.ok ) {
                        var activeTab = modalEl.querySelector('.modal-content.active'),
                            requireEmailVerification = true;

                        if(!activeTab.hasAttribute('data-email-verification')){ requireEmailVerification = false; }

                        if(requireEmailVerification){
                            __tnt.user.updateLoggedIn(modalTabSignupSuccess);

                        } else {
                            __tnt.user.firstLogin = true;
                            __tnt.user.updateLoggedIn();
                        }
                    }
                    else {
                        throw new Error('Signup failure - '+ oResp.statusText);
                    }
                }).catch(function(oErr){
                    if(errorEl){ errorEl.classList.remove('hide') }
                    if(submitButton && submitButton.disabled){ submitButton.disabled = false }
                    console.log(oErr);
                }).finally(function(){
                    var oRecaptchaInput = oForm.querySelector('.g-recaptcha');
                    // Check if widgetId is available (TNCMS-555283)
                    if (oRecaptchaInput && oRecaptchaInput.dataset.widgetId) {
                        var sRecaptchaId = oRecaptchaInput.dataset.widgetId;
                        if (grecaptcha.enterprise) {
                            grecaptcha.enterprise.reset(sRecaptchaId);
                        } else {
                            grecaptcha.reset(sRecaptchaId); // Remove this once TNCMS-555283 goes out since enterprise will be used everywhere
                        }
                    // Otherwise use regex method to get ID (not needed after TNCMS-555283 goes out)
                    } else {
                        oRecaptchaInput = oForm.querySelector('.g-recaptcha-response');
                        var sRecaptchaId;
                        if (oRecaptchaInput) {
                            sRecaptchaId = (oRecaptchaInput.id.search(/\d+/) != -1) ? oRecaptchaInput.id.match(/\d+/)[0] : '0';
                            grecaptcha.reset(sRecaptchaId);
                        }
                    }
                });

            }
        });
    }

    if(promoTab && promoForm){
        promoSpinner = promoTab.querySelector('.promo-loading');

        if(promoOpen){
            promoOpen.addEventListener('submit', function(oEv){
                oEv.preventDefault();

                var promoCode = promoOpen.elements.promoCode.value
                promoForm.elements.promoCode.value = promoCode;
                //promoForm.querySelector('button').click();

                __tnt.subscription.populatePromoModal();

                //__tnt.subscription.access.showTab(promoTab, 'access-offers-modal', 'access-methods');
            });
        }

        promoForm.addEventListener('submit', function(oEv){
            oEv.preventDefault();

            __tnt.subscription.populatePromoModal();
        });

    }
});

var checkedForPromoCode = false;
window.addEventListener('BLOXSubscriptionProductListViewed',function(ev){
    if(!checkedForPromoCode){
        var modalWrapperEl = document.getElementById('access-offers-modal'),
            modalEl = null,
            params = new URLSearchParams(document.location.search),
            paramsPromoCode = params.get("promoCode"),
            promoTab = document.getElementById('user-modal-tab-promo'),
            promoForm = document.getElementById('service-promo-fetch');

        if(paramsPromoCode){
            var promoCode = paramsPromoCode;
            promoForm.elements.promoCode.value = promoCode;
            //promoForm.querySelector('button').click();

            __tnt.subscription.populatePromoModal();
        }

        checkedForPromoCode = true;
    }
});
